@mixin mediaSm {
    @media screen and (max-width: 576px) {
        @content;
    }
}

@mixin mediaMd {
    @media screen and (max-width:768px) {
        @content;
    }
}

@mixin mediaLg {
    @media screen and (min-width: 769px) and (max-width: 1170px) {
        @content;
    }
}

@mixin mediaXl {
    @media screen and (min-width:1171px) {
        @content;
    }
}

@mixin mediaXXl {
    @media screen and (min-width:1704px) {
        @content;
    }
}

.default-theme {
    --bg: #FFFFFF;
    --link: #1890FF;
    --text-pri: #222222;
    --spacing: 16px;
    --half-spacing: calc(var(--spacing) * 0.5);
}

// ===== Default =====
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

// Scroll bar
body::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // border-radius: 10px;
    background-color: #001529;
}

body::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

body::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
    width: 12px;
    background-color: #001529;
}

body::-webkit-scrollbar {
    background-color: #f4f4f4;
}

body::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #000c17;
}

body::-webkit-scrollbar-thumb {
    background-color: #b1b1b1;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

// ====================

// ===== Header =====
.logo {
    height: 60px;
    width: 200px;
    margin: 4px;
    position: fixed;
    // background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logoImg {
    height: 54px;
}

.header {
    background-color: var(--bg);
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 2;
    padding-inline: 0 !important;

    .avatar {
        cursor: pointer;
        border: 1px solid var(--bg);

        &:hover {
            border: 1px solid var(--link);
        }
    }

    .ant-page-header-back-button {
        color: #FFF
    }

    .ant-page-header-heading-title {
        color: #FFFFFF
    }
}

// ====================

// ===== Footer =====
.footer {
    background-color: var(--bg);
}

// ====================

// ===== General =====
.content {
    margin: 24px 16px 0;
    // overflow: hidden;
    // max-height: calc(100vh - 72px - 24px);

    .content-content {
        background-color: var(--bg);
        padding: var(--spacing);
        margin-bottom: var(--spacing);

        .sub-italic {
            font-size: 12px;
            font-style: italic;
        }
    }
}

.display-none {
    display: none;
}

.container-content {
    max-width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;

    &.allow-overflow {
        overflow: auto;
    }
}

.row {
    display: flex;
    flex-direction: row;

    &.spaceBetween {
        justify-content: space-between;
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.column {
    display: flex;
    flex-direction: column;
}

// .container-flexbox {
//     display: flex;
//     flex-direction: column;
//     height: 100%;

//     &.allow-overflow {
//         overflow: auto;
//     }
// }

.container-flexgrow {
    flex-grow: 1;
    overflow: auto;
}

.container-nav-button {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: var(--spacing);

    .nav-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        border: 2px solid var(--text-pri);
        border-radius: 15px;
        cursor: pointer;

        .nav-button-icon {
            font-size: 40px;
        }

        .nav-button-text {
            font-size: 20px;
        }

        &:hover {
            color: var(--link);
            border-color: var(--link);
        }
    }
}

.container-card-site {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing);

    .card-site-deployment, .card-site-dashboard {
        border: 1px solid var(--text-pri);
        // width: 32%;
        flex: 1 0 250px;
        padding: var(--spacing);

        .title {
            font-size: 20px;
            font-weight: bold;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .subtitle {
            font-size: 16px;
            font-weight: 500;
        }

        .deployment-input {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ant-row {
                flex: 1;
                margin-right: var(--spacing);
            }

            .btn-minus {
                margin-bottom: 24px;
            }
        }
    }

    .card-site-dashboard {
        .duty-count {
            border: 1px solid var(--link);
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            margin: 5px 0;

            .count {
                font-size: 24px;
                font-weight: 700;
            }

        }

        .tag {
            margin-bottom: 5px;
            cursor: pointer;
        }
    } 
}

.action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.filter-applied {
        margin-top: 0;
    }
}

.filter {
    border: 1px solid var(--text-pri);

    .row {
        flex-wrap: wrap;
        justify-content: space-between;

        .ant-form-item {
            min-width: 150px;
            width: 48%;
        }
    }
}

.filter-applied {
    margin-top: var(--spacing);
    display: flex;
    flex-direction: row;

    .filter-applied-item {
        background-color: var(--text-pri);
        color: #FFFFFF;
        border-radius: 15px;
        padding: 0 10px;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }

        .close {
            cursor: pointer;
        }
    }
}

.table-row-clickable {
    cursor: pointer;
}

.table-cell-editabletable-action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
        margin-bottom: 5px;
    }
}

.table-cell-strikethrough {
    text-decoration: line-through;
}

.table-strikethrough {
    background-color: #d3d3d3;
}

.card-attendance,
.card-incident,
.card-patrol,
.card-pwm,
.card-parolCheckpoint,
.card-visitor,
.card-staff {
    margin-bottom: var(--spacing);

    .card-attendance-title,
    .card-incident-title,
    .card-patrol-title,
    .card-pwm-title,
    .card-parolCheckpoint-title,
    .card-visitor-title,
    .card-staff-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .row {
        .label {
            min-width: 95px;
            width: 20%;
        }

        .data {
            width: 80%;
        }

        .ant-image {
            margin-right: var(--spacing);

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.permission-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: var(--spacing);

    .permission-item {
        // background-color: pink;
        width: 49%;
        border: 1px solid grey;
        padding: var(--spacing);
    }
}

.form-permission-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-blue {
    color: #0047AB;
}

.text-green {
    color: #097969;
}

// inclusive
.below-sm,
.below-md {
    display: none !important;
}

// exclusive
.above-sm,
.above-md {
    display: block !important;
}

// ====================

// ===== Ant Design =====
.ant-descriptions-item-label {
    // align middle (vertical)
    margin: auto;
}

// .ant-layout-sider-zero-width-trigger {
//     background: transparent;
//     margin-left: -10px;
// }

.ant-modal-content {
    .nav-button {
        border: 2px solid;

        &:hover {
            // color: var(--link);
            color: #1890FF;
            // border-color: var(--link);
            border-color: #1890FF;
        }
    }
}
// ===== Form =====
.form-item-margin-bottom-minimise {
    margin-bottom: 8px;
}

.ant-form-item-explain {
    .ant-form-item-explain-error:not(:first-child) {
        display: none;
    }
}

// ====================

// ====================

// ===== Login =====
.login-form-forgot {
    float: right;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.login-form-button {
    width: 100%;
}

.login {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .container-login {
        flex: 1;
        max-width: 350px;
        margin-top: 100px;

        .login-body {
            background-color: #ffffff;
            padding: var(--spacing);
            border-radius: var(--border-radius);

            .login-header {
                text-align: center;
                font-size: 25px;
                font-weight: 700;
                margin-bottom: var(--spacing);
            }

            .login-subheader {
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: var(--spacing);
            }
        }
    }
}

// ====================

// ===== Settings =====
.emailverifiedstatus {
    margin-left: 5px;
    color: #79DE79;

    &.notverified {
        color: #fF6962;
    }
}

// ====================

// // ===== Contract =====
// .performanceindicator-input {
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     .ant-row {
//         margin-right: var(--spacing);
//     }

//     .btn-minus {
//         margin-bottom: 24px;
//     }
// }

// // ====================

// // ===== Occurrence =====

// .editButtons {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: var(--half-spacing);

//     .fillWidth {
//         width: 100%;
//     }
//     .fillContent {
//         width: fit-content;
//     }
// }

// // ====================

// // ===== Supervisory =====
// .supervisorycheck-overview-container {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//     flex-direction: row;
//     margin-bottom: var(--spacing);
//     flex-wrap: wrap;
//     gap: var(--spacing);

//     .supervisorycheck-overview-item {
//         border: solid 1px #000000;
//         border-radius: var(--spacing);
//         padding: 5px var(--spacing);
//         // width: 250px;
//         flex: 1 1 250px;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         align-items: center;
//         cursor: pointer;

//         .num {
//             font-size: 18px;
//             font-weight: 700;
//         }

//         &:hover {
//             background-color: #1890FF;
//             color: #FFFFFF;
//         }
//     } 
// }
// // ====================

// // ===== Modal =====
// .ticket-overview-container {
//     display: flex;
//     flex-direction: row;
//     gap: var(--spacing);

//     .item {
//         flex: 1 1 150px;
//     }
// }
// // ====================

// // ===== Modal =====
// .modal-deploymenttemplate {
//     .modal-content {
//         .item-radio {
//             .item-text {
//                 font-size: 16px;
//                 font-weight: 700;
//             }

//             .item-subtext {
//                 font-size: 12px;
//                 font-style: italic;
//             }
//         }
        
//         .ant-space-item .ant-radio-wrapper {
//             align-items: center;
//         }
//     }
// }
// // ====================

// ========================================
// RESPONSIVE CSS
// ========================================
// ===== Widescreen ======
@include mediaXl {
    .card-site-deployment, .card-site-dashboard {
        border: 1px solid var(--text-pri);
        max-width: 24%;
        flex: 1 0 250px;
        padding: var(--spacing);
    }
}

@include mediaXXl {}

// ===== Desktops and Laptops =====
@include mediaLg {
    .card-site-deployment, .card-site-dashboard {
        border: 1px solid var(--text-pri);
        max-width: 32%;
        flex: 1 0 250px;
        padding: var(--spacing);
    }
}

//  ===== Tablets and Small Laptops =====
@include mediaMd {
    .below-md {
        display: block !important;
    }

    .above-md {
        display: none !important;
    }

    .content {
        // for card scroll detection
        overflow: hidden;
        max-height: calc(100vh - 72px - 24px);
    }

    .container-nav-button {
        flex-direction: column;
        margin-bottom: 0;

        .nav-button {
            width: 100%;
            margin-bottom: var(--spacing);
        }
    }

    .ant-modal-content {
        .nav-button {
            margin-bottom: 16px;
        }
    }

    .filter .row {
        flex-direction: column;

        .ant-form-item {
            width: 100%;
        }
    }

    // .card-site-deployment, .card-site-dashboard {
    //     border: 1px solid var(--text-pri);
    //     max-width: 49%;
    //     flex: 1 0 250px;
    //     padding: var(--spacing);
    // }

    // .supervisorycheck-overview-container {
    //     grid-template-columns: 1fr 1fr 1fr;
    // }
}

// ===== Smartphones =====
@include mediaSm {
    .below-sm {
        display: block !important;
    }

    .above-sm {
        display: none !important;
    }

    .card-site-deployment, .card-site-dashboard {
        border: 1px solid var(--text-pri);
        max-width: 100%;
        flex: 1 0 250px;
        padding: var(--spacing);
    }

    // .supervisorycheck-overview-container {
    //     grid-template-columns: 1fr 1fr;
    // }
}