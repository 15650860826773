.default-theme {
  --bg: #FFFFFF;
  --link: #1890FF;
  --text-pri: #222222;
  --spacing: 16px;
  --half-spacing: calc(var(--spacing) * 0.5);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #001529;
}

body::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

body::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: #001529;
}

body::-webkit-scrollbar {
  background-color: #f4f4f4;
}

body::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000c17;
}

body::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.logo {
  height: 60px;
  width: 200px;
  margin: 4px;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.logoImg {
  height: 54px;
}

.header {
  background-color: var(--bg);
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  padding-inline: 0 !important;
}

.header .avatar {
  cursor: pointer;
  border: 1px solid var(--bg);
}

.header .avatar:hover {
  border: 1px solid var(--link);
}

.header .ant-page-header-back-button {
  color: #FFF;
}

.header .ant-page-header-heading-title {
  color: #FFFFFF;
}

.footer {
  background-color: var(--bg);
}

.content {
  margin: 24px 16px 0;
}

.content .content-content {
  background-color: var(--bg);
  padding: var(--spacing);
  margin-bottom: var(--spacing);
}

.content .content-content .sub-italic {
  font-size: 12px;
  font-style: italic;
}

.display-none {
  display: none;
}

.container-content {
  max-width: 1200px;
  height: 100%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container-content.allow-overflow {
  overflow: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.row.spaceBetween {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container-flexgrow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: auto;
}

.container-nav-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-bottom: var(--spacing);
}

.container-nav-button .nav-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 150px;
  height: 150px;
  border: 2px solid var(--text-pri);
  border-radius: 15px;
  cursor: pointer;
}

.container-nav-button .nav-button .nav-button-icon {
  font-size: 40px;
}

.container-nav-button .nav-button .nav-button-text {
  font-size: 20px;
}

.container-nav-button .nav-button:hover {
  color: var(--link);
  border-color: var(--link);
}

.container-card-site {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: var(--spacing);
}

.container-card-site .card-site-deployment, .container-card-site .card-site-dashboard {
  border: 1px solid var(--text-pri);
  -webkit-box-flex: 1;
      -ms-flex: 1 0 250px;
          flex: 1 0 250px;
  padding: var(--spacing);
}

.container-card-site .card-site-deployment .title, .container-card-site .card-site-dashboard .title {
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.container-card-site .card-site-deployment .subtitle, .container-card-site .card-site-dashboard .subtitle {
  font-size: 16px;
  font-weight: 500;
}

.container-card-site .card-site-deployment .deployment-input, .container-card-site .card-site-dashboard .deployment-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container-card-site .card-site-deployment .deployment-input .ant-row, .container-card-site .card-site-dashboard .deployment-input .ant-row {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: var(--spacing);
}

.container-card-site .card-site-deployment .deployment-input .btn-minus, .container-card-site .card-site-dashboard .deployment-input .btn-minus {
  margin-bottom: 24px;
}

.container-card-site .card-site-dashboard .duty-count {
  border: 1px solid var(--link);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0;
}

.container-card-site .card-site-dashboard .duty-count .count {
  font-size: 24px;
  font-weight: 700;
}

.container-card-site .card-site-dashboard .tag {
  margin-bottom: 5px;
  cursor: pointer;
}

.action-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.action-row.filter-applied {
  margin-top: 0;
}

.filter {
  border: 1px solid var(--text-pri);
}

.filter .row {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.filter .row .ant-form-item {
  min-width: 150px;
  width: 48%;
}

.filter-applied {
  margin-top: var(--spacing);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.filter-applied .filter-applied-item {
  background-color: var(--text-pri);
  color: #FFFFFF;
  border-radius: 15px;
  padding: 0 10px;
  margin-right: 5px;
}

.filter-applied .filter-applied-item:last-child {
  margin-right: 0;
}

.filter-applied .filter-applied-item .close {
  cursor: pointer;
}

.table-row-clickable {
  cursor: pointer;
}

.table-cell-editabletable-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.table-cell-editabletable-action button {
  margin-bottom: 5px;
}

.table-cell-strikethrough {
  text-decoration: line-through;
}

.table-strikethrough {
  background-color: #d3d3d3;
}

.card-attendance,
.card-incident,
.card-patrol,
.card-pwm,
.card-parolCheckpoint,
.card-visitor,
.card-staff {
  margin-bottom: var(--spacing);
}

.card-attendance .card-attendance-title,
.card-attendance .card-incident-title,
.card-attendance .card-patrol-title,
.card-attendance .card-pwm-title,
.card-attendance .card-parolCheckpoint-title,
.card-attendance .card-visitor-title,
.card-attendance .card-staff-title,
.card-incident .card-attendance-title,
.card-incident .card-incident-title,
.card-incident .card-patrol-title,
.card-incident .card-pwm-title,
.card-incident .card-parolCheckpoint-title,
.card-incident .card-visitor-title,
.card-incident .card-staff-title,
.card-patrol .card-attendance-title,
.card-patrol .card-incident-title,
.card-patrol .card-patrol-title,
.card-patrol .card-pwm-title,
.card-patrol .card-parolCheckpoint-title,
.card-patrol .card-visitor-title,
.card-patrol .card-staff-title,
.card-pwm .card-attendance-title,
.card-pwm .card-incident-title,
.card-pwm .card-patrol-title,
.card-pwm .card-pwm-title,
.card-pwm .card-parolCheckpoint-title,
.card-pwm .card-visitor-title,
.card-pwm .card-staff-title,
.card-parolCheckpoint .card-attendance-title,
.card-parolCheckpoint .card-incident-title,
.card-parolCheckpoint .card-patrol-title,
.card-parolCheckpoint .card-pwm-title,
.card-parolCheckpoint .card-parolCheckpoint-title,
.card-parolCheckpoint .card-visitor-title,
.card-parolCheckpoint .card-staff-title,
.card-visitor .card-attendance-title,
.card-visitor .card-incident-title,
.card-visitor .card-patrol-title,
.card-visitor .card-pwm-title,
.card-visitor .card-parolCheckpoint-title,
.card-visitor .card-visitor-title,
.card-visitor .card-staff-title,
.card-staff .card-attendance-title,
.card-staff .card-incident-title,
.card-staff .card-patrol-title,
.card-staff .card-pwm-title,
.card-staff .card-parolCheckpoint-title,
.card-staff .card-visitor-title,
.card-staff .card-staff-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.card-attendance .row .label,
.card-incident .row .label,
.card-patrol .row .label,
.card-pwm .row .label,
.card-parolCheckpoint .row .label,
.card-visitor .row .label,
.card-staff .row .label {
  min-width: 95px;
  width: 20%;
}

.card-attendance .row .data,
.card-incident .row .data,
.card-patrol .row .data,
.card-pwm .row .data,
.card-parolCheckpoint .row .data,
.card-visitor .row .data,
.card-staff .row .data {
  width: 80%;
}

.card-attendance .row .ant-image,
.card-incident .row .ant-image,
.card-patrol .row .ant-image,
.card-pwm .row .ant-image,
.card-parolCheckpoint .row .ant-image,
.card-visitor .row .ant-image,
.card-staff .row .ant-image {
  margin-right: var(--spacing);
}

.card-attendance .row .ant-image:last-child,
.card-incident .row .ant-image:last-child,
.card-patrol .row .ant-image:last-child,
.card-pwm .row .ant-image:last-child,
.card-parolCheckpoint .row .ant-image:last-child,
.card-visitor .row .ant-image:last-child,
.card-staff .row .ant-image:last-child {
  margin-right: 0;
}

.permission-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  row-gap: var(--spacing);
}

.permission-container .permission-item {
  width: 49%;
  border: 1px solid grey;
  padding: var(--spacing);
}

.form-permission-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.text-blue {
  color: #0047AB;
}

.text-green {
  color: #097969;
}

.below-sm,
.below-md {
  display: none !important;
}

.above-sm,
.above-md {
  display: block !important;
}

.ant-descriptions-item-label {
  margin: auto;
}

.ant-modal-content .nav-button {
  border: 2px solid;
}

.ant-modal-content .nav-button:hover {
  color: #1890FF;
  border-color: #1890FF;
}

.form-item-margin-bottom-minimise {
  margin-bottom: 8px;
}

.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

.login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.login .container-login {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 350px;
  margin-top: 100px;
}

.login .container-login .login-body {
  background-color: #ffffff;
  padding: var(--spacing);
  border-radius: var(--border-radius);
}

.login .container-login .login-body .login-header {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: var(--spacing);
}

.login .container-login .login-body .login-subheader {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: var(--spacing);
}

.emailverifiedstatus {
  margin-left: 5px;
  color: #79DE79;
}

.emailverifiedstatus.notverified {
  color: #fF6962;
}

@media screen and (min-width: 1171px) {
  .card-site-deployment, .card-site-dashboard {
    border: 1px solid var(--text-pri);
    max-width: 24%;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 250px;
            flex: 1 0 250px;
    padding: var(--spacing);
  }
}

@media screen and (min-width: 769px) and (max-width: 1170px) {
  .card-site-deployment, .card-site-dashboard {
    border: 1px solid var(--text-pri);
    max-width: 32%;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 250px;
            flex: 1 0 250px;
    padding: var(--spacing);
  }
}

@media screen and (max-width: 768px) {
  .below-md {
    display: block !important;
  }
  .above-md {
    display: none !important;
  }
  .content {
    overflow: hidden;
    max-height: calc(100vh - 72px - 24px);
  }
  .container-nav-button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0;
  }
  .container-nav-button .nav-button {
    width: 100%;
    margin-bottom: var(--spacing);
  }
  .ant-modal-content .nav-button {
    margin-bottom: 16px;
  }
  .filter .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .filter .row .ant-form-item {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .below-sm {
    display: block !important;
  }
  .above-sm {
    display: none !important;
  }
  .card-site-deployment, .card-site-dashboard {
    border: 1px solid var(--text-pri);
    max-width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 250px;
            flex: 1 0 250px;
    padding: var(--spacing);
  }
}
